export const boxTypes = [2, 3, 4, 5, 6, 7];

export const itemParams = [
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 1,
    itemName: 'Blue',
    rarity: 280000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 2,
    itemName: 'Orange',
    rarity: 230000,
    level: 1,
    experience: 20,
  },
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 3,
    itemName: 'Purple',
    rarity: 190000,
    level: 1,
    experience: 50,
  },
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 4,
    itemName: 'Red',
    rarity: 150000,
    level: 1,
    experience: 85,
  },
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 5,
    itemName: 'Green',
    rarity: 100000,
    level: 1,
    experience: 180,
  },
  {
    boxType: 2,
    boxName: 'Background',
    itemId: 6,
    itemName: 'Grey',
    rarity: 50000,
    level: 1,
    experience: 460,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 1,
    itemName: 'Gakuran',
    rarity: 330000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 2,
    itemName: 'Bandana',
    rarity: 120000,
    level: 1,
    experience: 175,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 3,
    itemName: 'Tropical',
    rarity: 150000,
    level: 1,
    experience: 120,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 4,
    itemName: 'Hoodie',
    rarity: 140000,
    level: 1,
    experience: 135,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 5,
    itemName: 'Stonks',
    rarity: 100000,
    level: 1,
    experience: 230,
  },
  {
    boxType: 3,
    boxName: 'Body',
    itemId: 6,
    itemName: 'Casual',
    rarity: 160000,
    level: 1,
    experience: 110,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 1,
    itemName: 'Normal',
    rarity: 300000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 2,
    itemName: 'Bored',
    rarity: 130000,
    level: 1,
    experience: 130,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 3,
    itemName: 'Laser',
    rarity: 60000,
    level: 1,
    experience: 400,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 4,
    itemName: 'Glasses1',
    rarity: 90000,
    level: 1,
    experience: 235,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 5,
    itemName: 'Glasses2',
    rarity: 110000,
    level: 1,
    experience: 170,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 6,
    itemName: 'Glasses3',
    rarity: 160000,
    level: 1,
    experience: 90,
  },
  {
    boxType: 4,
    boxName: 'Eyes',
    itemId: 7,
    itemName: '3DGlasses',
    rarity: 150000,
    level: 1,
    experience: 100,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 1,
    itemName: 'Plumber',
    rarity: 60000,
    level: 1,
    experience: 530,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 2,
    itemName: 'Halo',
    rarity: 65000,
    level: 1,
    experience: 480,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 3,
    itemName: 'Mohawk',
    rarity: 70000,
    level: 1,
    experience: 440,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 4,
    itemName: 'Rainbow',
    rarity: 90000,
    level: 1,
    experience: 320,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 5,
    itemName: 'Helmet',
    rarity: 95000,
    level: 1,
    experience: 300,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 6,
    itemName: 'Top',
    rarity: 75000,
    level: 1,
    experience: 400,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 7,
    itemName: 'Wizard',
    rarity: 65000,
    level: 1,
    experience: 480,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 8,
    itemName: 'Crown',
    rarity: 25000,
    level: 1,
    experience: 1420,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 9,
    itemName: 'Pirate',
    rarity: 40000,
    level: 1,
    experience: 850,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 10,
    itemName: 'Space',
    rarity: 35000,
    level: 1,
    experience: 1000,
  },
  {
    boxType: 5,
    boxName: 'Head',
    itemId: 11,
    itemName: 'None',
    rarity: 380000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 1,
    itemName: 'Grey',
    rarity: 370000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 2,
    itemName: 'Green',
    rarity: 160000,
    level: 1,
    experience: 130,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 3,
    itemName: 'Brown',
    rarity: 120000,
    level: 1,
    experience: 210,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 4,
    itemName: 'Gold',
    rarity: 60000,
    level: 1,
    experience: 520,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 5,
    itemName: 'Purple',
    rarity: 130000,
    level: 1,
    experience: 180,
  },
  {
    boxType: 6,
    boxName: 'Fur',
    itemId: 6,
    itemName: 'Pink',
    rarity: 160000,
    level: 1,
    experience: 130,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 1,
    itemName: 'Bone',
    rarity: 180000,
    level: 1,
    experience: 70,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 2,
    itemName: 'Grrrrr',
    rarity: 310000,
    level: 1,
    experience: 1,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 3,
    itemName: 'Soother',
    rarity: 160000,
    level: 1,
    experience: 95,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 4,
    itemName: 'Beard',
    rarity: 150000,
    level: 1,
    experience: 110,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 5,
    itemName: 'Mask',
    rarity: 80000,
    level: 1,
    experience: 290,
  },
  {
    boxType: 7,
    boxName: 'Mouth',
    itemId: 6,
    itemName: 'Gold',
    rarity: 120000,
    level: 1,
    experience: 160,
  },
];
