import React from 'react';
import Footer from 'components/Footer';
// import AddressFooter from 'components/AddressFooter';
import DonateComponent from 'components/donate/DonateComponent';

export default function Donate() {
  return (
    <>
      <DonateComponent />
      <Footer />
    </>
  );
}
